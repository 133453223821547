
import jsPDF from "jspdf";

const docOptions = {
    orientation: "landscape",
    format: [40, 100],
    unit: "mm", // 210 x 297
    autoPaging: 'text',
};

var doc = new jsPDF(docOptions);

function _printNormalAndBold(inputValue, startX, Y, fontSize = 12) {
    const arrayOfNormalAndBoldText = inputValue.split('**');
    arrayOfNormalAndBoldText.map((text, i) => {
        doc.setFontSize(fontSize).setFont(undefined, 'bold');
        // every even item is a normal font weight item
        if (i % 2 === 0) {
            doc.setFont(undefined, 'normal');
        }
        doc.text(text, startX, Y);
        startX = startX + doc.getTextWidth(text);
    });
}

export default {
    create: async (item) => {
        doc = new jsPDF(docOptions);
        if (item.order) {
            doc.rect(5, 5, 90, 30);
            _printNormalAndBold(`${item.route} (${item.order})`, 10, 22, 30);
            // if (item.driver)
            //     _printNormalAndBold(`**Motorista:** ${item.driver}`, 10, 32, 10);
        }
        return doc;
    },
};